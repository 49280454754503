import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { rem } from 'polished';
import { AppComponent, ModalType } from '../typescript/typings';
import ArrowRight from 'packages/common/assets/svg/arrow-right.svg';
import GameIcon from '../public/img/game.svg';
import ClipsIcon from 'packages/common/assets/svg/clips.svg';
import AnalyticsIcon from 'packages/common/assets/svg/analytics.svg';
import TvIcon from 'packages/common/assets/svg/tv.svg';
import SupportIcon from 'packages/common/assets/svg/support-chat.svg';
import SimulcastIcon from 'packages/common/assets/svg/simulcast.svg';
import CheckIcon from 'packages/common/assets/svg/check.svg';
import AppIcon from 'packages/common/assets/svg/app-logo.svg';
import CloseIcon from 'packages/common/assets/svg/close.svg';
import AvatarIcon from 'packages/common/assets/svg/avatar-placeholder.svg';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { showModal, track } from '../state/app';
import Row from 'packages/common/base/styles/components/Row';
import Centered from 'packages/common/base/styles/components/Centered';
import ProgressDots from '../components/ProgressDots';
import Image from 'next/image';
import { Breakpoints } from 'packages/common/base/styles';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import ProductRoadmap from '../components/marketing/ProductRoadmap';
import FadeOverlayStyles from '../styles/common/fadeOverlay';
import MarketingNav, { MarketingNavOptions } from '../components/marketing/MarketingNav';
import {
    AvatarStyled,
    CheckStyled,
    DarkButtonStyled,
    DescriptionStyled,
    Heading1Styled,
    Heading2Styled,
    Heading3Styled,
    OutlineButtonStyled,
    OverlayCloseButtonStyled,
    RedButtonStyled,
    ScrollLock,
    SectionStyled,
    WidgetDescriptionStyled,
    WidgetHeadingStyled,
    WidgetHeading_Small_Styled,
} from '../styles/common/marketing';
import CreatorApplication from '../components/marketing/CreatorApplication';
import { testimonials, wallOfLove } from '../constants/marketing';
import { transparentize } from 'polished';
import { useDetectClickOutside } from 'packages/common/base/hooks';

interface Marketing {
    className?: string;
}

const isMobile = () =>
    typeof window === 'undefined'
        ? null
        : !window.matchMedia(`(min-width: ${rem(Breakpoints.Small)})`).matches;

const Marketing: AppComponent<Marketing> = (props) => {
    const { className } = props;

    const dispatch = useAppDispatch();
    const mobile = useAppSelector((state) => state.app.mobile);

    const [showMenu, setShowMenu] = useState(false);
    const [showMoreTestimonials, setShowMoreTestimonials] = useState(false);
    const [showProductRoadmap, setShowProductRoadmap] = useState(false);
    const [showApplication, setShowApplication] = useState(false);
    const [heroMuted, setHeroMuted] = useState(true);
    const [testimonialIndex, setTestimonialIndex] = useState(1);

    const heroVideo = useRef<HTMLVideoElement>(null);
    const featureSection = useRef<HTMLDivElement>(null);
    const carousel = useRef<HTMLDivElement>(null);
    const roadmapOverlay = useRef<HTMLDivElement>(null);

    const handleApplicationError = useCallback(
        (errors: any[]) => {
            errors.forEach((error) => {
                dispatch(track({ event: 'Creator Application Submission Error', error }));
            });
        },
        [dispatch]
    );

    const handleClickNavOption = (option: MarketingNavOptions) => {
        dispatch(track({ event: 'Click Marketing Menu Option', option }));

        switch (option) {
            case MarketingNavOptions.Login:
                dispatch(showModal({ type: ModalType.Login }));
                dispatch(track({ event: 'Click Login' }));
                break;
            case MarketingNavOptions.Join:
                setShowApplication(true);
                break;
            case MarketingNavOptions.Features:
                setShowMenu(false);
                featureSection.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
        }
    };

    useEffect(() => {
        const testimonialsObserver = new IntersectionObserver(
            (entries) => {
                if (!isMobile()) return;

                entries.forEach((el) => {
                    if (el.isIntersecting) {
                        const index = parseInt(el.target.getAttribute('data-index'));
                        setTestimonialIndex(index);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        const videoIntersectionHandler = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((el) => {
                if (el.isIntersecting) {
                    (el.target as HTMLVideoElement).play();
                    // video.play();
                } else {
                    (el.target as HTMLVideoElement).pause();
                }
            });
        };

        const heroVideoObserver = new IntersectionObserver(videoIntersectionHandler, {
            threshold: 0.25,
        });

        const featureVideoObserver = new IntersectionObserver(videoIntersectionHandler, {
            threshold: 0.5,
        });

        heroVideoObserver.observe(heroVideo.current);

        const featureVideos = Array.from(document.querySelectorAll('.feature_video'));
        featureVideos.forEach((video) => featureVideoObserver.observe(video));

        const testimonials = Array.from(carousel.current.children);
        testimonials.forEach((el) => testimonialsObserver.observe(el));

        return () => {
            if (heroVideo.current) {
                heroVideoObserver.unobserve(heroVideo.current);
            }

            testimonials.forEach((el) => {
                if (el) {
                    testimonialsObserver.unobserve(el);
                }
            });
            featureVideos.forEach((video) => {
                if (video) {
                    featureVideoObserver.unobserve(video);
                }
            });
        };
    }, []);

    useDetectClickOutside({
        enabled: showProductRoadmap,
        elements: [roadmapOverlay.current],
        mobile,
        escapeKey: true,
        onClickOutside: useCallback(() => {
            setShowProductRoadmap(false);
        }, []),
    });

    return (
        <MarketingStyled className={className}>
            <FontFaces />
            <SEO />
            {(showProductRoadmap || showApplication || showMenu) && <ScrollLock />}
            <MarketingNav
                menu
                open={showMenu}
                mobile={mobile}
                onClickMenu={() => {
                    dispatch(track({ event: 'Click Menu' }));
                    setShowMenu(true);
                }}
                onClose={() => {
                    setShowMenu(false);
                }}
                onClickOption={handleClickNavOption}
            />
            {/* Hero */}
            <WideSectionStyled>
                <SectionStyled>
                    <Heading1Styled>Win or lose, sports are better together</Heading1Styled>
                    <Hero_DescriptionStyled>
                        Playback is where creators and their communities hang out and watch live
                        games together. Want to watch with your community? Apply to be a Playback
                        Creator!
                    </Hero_DescriptionStyled>
                    <Hero_ButtonsStyled justifyContent="center" alignItems="center">
                        <RedButtonStyled
                            onClick={() => {
                                dispatch(
                                    track({ event: 'Click Marketing Application CTA', cta: 'hero' })
                                );
                                setShowApplication(true);
                            }}
                        >
                            Apply now
                            <ArrowRight />
                        </RedButtonStyled>
                        <DarkButtonStyled
                            onClick={() => {
                                featureSection.current.scrollIntoView({ behavior: 'smooth' });
                                dispatch(track({ event: 'Click Marketing Hero Features' }));
                            }}
                        >
                            Features
                        </DarkButtonStyled>
                    </Hero_ButtonsStyled>
                </SectionStyled>
                <Hero_VideoContainerStyled>
                    <video
                        ref={heroVideo}
                        title="Playback Demo"
                        muted
                        preload="metadata"
                        loop
                        playsInline
                    >
                        <source src="video/marketing_hero.webm" type="video/webm" />
                        <source src="video/marketing_hero.mp4" type="video/mp4" />
                    </video>
                    <Hero_PlayButtonStyled
                        onClick={() => {
                            if (heroMuted) {
                                heroVideo.current.currentTime = 0;
                                heroVideo.current.muted = false;
                                heroVideo.current.volume = 1;
                            } else {
                                heroVideo.current.muted = true;
                            }

                            dispatch(
                                track({
                                    event: heroMuted
                                        ? 'Play Marketing Hero Video'
                                        : 'Mute Marketing Hero Video',
                                })
                            );

                            setHeroMuted(!heroMuted);
                        }}
                    >
                        {heroMuted ? 'Play' : 'Mute'}
                    </Hero_PlayButtonStyled>
                </Hero_VideoContainerStyled>
            </WideSectionStyled>
            {/* Features */}
            <SectionStyled ref={featureSection}>
                <Features_HeadingStyled>
                    Everything you need to watch with your community
                </Features_HeadingStyled>
                <FeatureCard
                    wide
                    heading="Stream any live broadcast together"
                    description="Watch any televised broadcast or streaming video, perfectly synced up."
                    videoSrc="video/marketing_stream.mp4"
                    videoAspectRatio={590 / 388}
                />
                <Feature_CardRowStyled>
                    <FeatureCard
                        heading="Add your own commentary, play by play or analysis"
                        description="Viewers can raise their hand to request to join you on stage."
                        videoSrc="video/marketing_commentary.mp4"
                        videoAspectRatio={463 / 324}
                    />
                    <FeatureCard
                        heading="Create a home for your community"
                        description="Everyone can chat and stream, even when you’re not present."
                        videoSrc="video/marketing_home.mp4"
                        videoAspectRatio={368 / 274}
                    />
                </Feature_CardRowStyled>
                <Feature_SummaryContainerStyled>
                    <FeatureSummary
                        icon={<ClipsIcon />}
                        heading="Clips"
                        description="Get full recordings of every watch party"
                    />
                    <FeatureSummary
                        icon={<AnalyticsIcon />}
                        heading="Analytics"
                        description="Get audience insights and track your performance"
                    />
                    <FeatureSummary
                        icon={<TvIcon />}
                        heading="TV Authentication"
                        description="Get access by verifying your TV provider"
                    />
                    <FeatureSummary
                        icon={<AppIcon />}
                        heading="iOS App"
                        description="Notify your followers when you go live. (Android coming soon.)"
                    />
                    <FeatureSummary
                        icon={<SupportIcon />}
                        heading="Moderation"
                        description="Add moderators to help you manage the chat and stage"
                    />
                    <FeatureSummary
                        icon={<SimulcastIcon />}
                        heading="Simulcast"
                        description="Publish your camera feed to other streaming platforms simultaneously"
                    />
                </Feature_SummaryContainerStyled>
                <OutlineButtonStyled
                    onClick={() => {
                        dispatch(track({ event: 'Click Marketing View Roadmap' }));
                        setShowProductRoadmap(true);
                    }}
                >
                    View roadmap
                </OutlineButtonStyled>
            </SectionStyled>
            {/* Testimonials */}
            <SectionStyled>
                <TestimonialsHeadingStyled>
                    <Heading2Styled>Become a Playback Partner</Heading2Styled>
                    <DescriptionStyled>
                        Apply to join the Playback Creator Partner Program and earn when you host
                        streams.
                    </DescriptionStyled>
                    <RedButtonStyled
                        onClick={() => {
                            dispatch(
                                track({ event: 'Click Marketing Application CTA', cta: 'body' })
                            );
                            setShowApplication(true);
                        }}
                    >
                        Apply
                        <ArrowRight />
                    </RedButtonStyled>
                </TestimonialsHeadingStyled>
                <TestimonialsStyled>
                    <Testimonials_ItemsStyled ref={carousel} activeIndex={testimonialIndex}>
                        {testimonials.map((testimonial, i) => (
                            <Testimonials_CardStyled
                                key={testimonial.name}
                                data-index={i}
                                active={testimonialIndex === i}
                                before={testimonialIndex > i}
                                onClick={() => {
                                    dispatch(
                                        track({
                                            event: 'Click Testimonial Carousel Card',
                                            index: i,
                                            next: i > testimonialIndex,
                                            previous: i < testimonialIndex,
                                        })
                                    );
                                    setTestimonialIndex(i);
                                }}
                            >
                                {'quote' in testimonial ? (
                                    <QuoteCard
                                        creatorName={testimonial.name}
                                        roomName={testimonial.roomName}
                                        quote={testimonial.quote}
                                        avatar={testimonial.icon}
                                    />
                                ) : (
                                    <CreatorCard
                                        name={testimonial.name}
                                        roomName={testimonial.roomName}
                                        joined={testimonial.joined}
                                        icon={testimonial.icon}
                                    />
                                )}
                            </Testimonials_CardStyled>
                        ))}
                    </Testimonials_ItemsStyled>
                </TestimonialsStyled>
                <ProgressDots
                    count={testimonials.length}
                    current={testimonialIndex}
                    onClickItem={(i) => {
                        dispatch(track({ event: 'Click Testimonial Carousel Dot', index: i }));
                        setTestimonialIndex(i);
                    }}
                />
                <Testimonials_MessagesStyled>
                    <Testimonials_MessageColumnStyled>
                        {wallOfLove.slice(0, Math.floor(wallOfLove.length / 2)).map((love) => (
                            <ChatMessage key={love.name} name={love.name} message={love.quote} />
                        ))}
                    </Testimonials_MessageColumnStyled>
                    <Testimonials_MessageColumnStyled $hidden={!showMoreTestimonials}>
                        {wallOfLove
                            .slice(Math.floor(wallOfLove.length / 2), wallOfLove.length - 1)
                            .map((love) => (
                                <ChatMessage
                                    key={love.name}
                                    name={love.name}
                                    message={love.quote}
                                />
                            ))}
                    </Testimonials_MessageColumnStyled>
                </Testimonials_MessagesStyled>
                {!showMoreTestimonials && (
                    <Testimonials_ViewMoreButtonStyled
                        onClick={() => {
                            setShowMoreTestimonials(true);
                            dispatch(track({ event: 'Click Marketing View More Testimonials' }));
                        }}
                    >
                        View more
                    </Testimonials_ViewMoreButtonStyled>
                )}
            </SectionStyled>
            {/* Footer */}
            <SectionStyled>
                <Footer_IconContainerStyled>
                    <GameIcon />
                </Footer_IconContainerStyled>
                <Footer_HeadingStyled>Let’s grow your community this season</Footer_HeadingStyled>
                <RedButtonStyled
                    onClick={() => {
                        dispatch(
                            track({ event: 'Click Marketing Application CTA', cta: 'footer' })
                        );
                        setShowApplication(true);
                    }}
                >
                    Join now
                    <ArrowRight />
                </RedButtonStyled>
            </SectionStyled>
            <FooterStyled />
            <ProductRoadmapOverlayStyled ref={roadmapOverlay} visible={showProductRoadmap} slide>
                <ProductRoadmapOverlay_ContentsStyled>
                    <Heading2Styled>Product Roadmap</Heading2Styled>
                    {/* <Link href="/roadmap">
                    <RedButtonLinkStyled href="/roadmap" title="Product Roadmap">
                    Full roadmap
                    </RedButtonLinkStyled>
                </Link> */}
                    <ProductRoadmapStyled />
                </ProductRoadmapOverlay_ContentsStyled>
                <OverlayCloseButtonStyled
                    onClick={() => {
                        dispatch(track({ event: 'Click Marketing Close Product Roadmap' }));
                        setShowProductRoadmap(false);
                    }}
                >
                    <CloseIcon />
                </OverlayCloseButtonStyled>
            </ProductRoadmapOverlayStyled>
            <CreatorApplicationOverlayStyled visible={showApplication}>
                <MarketingNav
                    open
                    mobile={mobile}
                    onClickOption={handleClickNavOption}
                    onClose={() => {
                        dispatch(track({ event: 'Click Marketing Close Application' }));
                        setShowApplication(false);
                    }}
                />
                <CreatorApplicationStyled
                    onSubmit={(application) => {
                        dispatch(track({ event: 'Submit Creator Application', application }));
                    }}
                    onClose={() => {
                        dispatch(track({ event: 'Complete Marketing Close Application' }));
                        setShowApplication(false);
                    }}
                    onError={handleApplicationError}
                    onSignup={() => {
                        dispatch(showModal({ type: ModalType.Signup }));
                        dispatch(track({ event: 'Click Signup' }));
                    }}
                />
            </CreatorApplicationOverlayStyled>
        </MarketingStyled>
    );
};

interface QuoteCard {
    className?: string;
    avatar?: string;
    creatorName: string;
    roomName: string;
    quote: string;
}

const QuoteCard: React.FC<QuoteCard> = ({ className, avatar, creatorName, roomName, quote }) => {
    return (
        <QuoteCardStyled className={className}>
            <Testimonials_CreatorStyled>
                <AvatarStyled>
                    {avatar ? (
                        <Image src={avatar} alt={creatorName} height="100%" width="100%" />
                    ) : (
                        <AvatarIcon />
                    )}
                </AvatarStyled>
                <div>
                    <Testimonials_Creator_NameStyled>
                        <WidgetHeading_Small_Styled>{creatorName}</WidgetHeading_Small_Styled>
                        <CheckStyled>
                            <CheckIcon />
                        </CheckStyled>
                    </Testimonials_Creator_NameStyled>
                    <WidgetDescriptionStyled>{roomName}</WidgetDescriptionStyled>
                </div>
            </Testimonials_CreatorStyled>
            <QuoteCard_QuoteStyled>{quote}</QuoteCard_QuoteStyled>
            <CreatorPartnerBadgeStyled>
                <CheckStyled>
                    <CheckIcon />
                </CheckStyled>
                Official Playback Partner
            </CreatorPartnerBadgeStyled>
        </QuoteCardStyled>
    );
};

interface CreatorCard {
    className?: string;
    name: string;
    roomName: string;
    joined: string;
    icon?: string;
}

const CreatorCard: React.FC<CreatorCard> = (props) => {
    const { className, name, roomName, joined, icon } = props;

    return (
        <CreatorCardStyled className={className}>
            <CreatorPartnerBadgeStyled>
                <CheckStyled>
                    <CheckIcon />
                </CheckStyled>
                Official Playback Partner
            </CreatorPartnerBadgeStyled>
            <CreatorCard_NameStyled>{name}</CreatorCard_NameStyled>
            <Testimonials_CreatorStyled>
                <AvatarStyled>
                    {icon ? (
                        <Image src={icon} alt={name} height="100%" width="100%" />
                    ) : (
                        <AvatarIcon />
                    )}
                </AvatarStyled>
                <div>
                    <Testimonials_Creator_NameStyled>
                        <WidgetHeading_Small_Styled>{roomName}</WidgetHeading_Small_Styled>
                        <CheckStyled>
                            <CheckIcon />
                        </CheckStyled>
                    </Testimonials_Creator_NameStyled>
                    <WidgetDescriptionStyled>{`Streaming since ${joined}`}</WidgetDescriptionStyled>
                </div>
            </Testimonials_CreatorStyled>
        </CreatorCardStyled>
    );
};

interface FeatureCard {
    className?: string;
    heading: string;
    description: string;
    videoSrc: string;
    videoAspectRatio: number;
    wide?: boolean;
}

const FeatureCard: React.FC<FeatureCard> = ({
    className,
    heading,
    description,
    videoSrc,
    videoAspectRatio,
    wide = false,
}) => {
    return (
        <Feature_CardStyled wide={wide} className={className}>
            <Feature_Card_DetailsStyled>
                <Heading3Styled>{heading}</Heading3Styled>
                <DescriptionStyled>{description}</DescriptionStyled>
            </Feature_Card_DetailsStyled>
            <Feature_Card_VideoContainerStyled aspectRatio={videoAspectRatio}>
                <video
                    className="feature_video"
                    title={heading}
                    preload="metadata"
                    muted
                    loop
                    playsInline
                >
                    <source src={videoSrc} type="video/mp4" />
                </video>
            </Feature_Card_VideoContainerStyled>
        </Feature_CardStyled>
    );
};

interface FeatureSummary {
    className?: string;
    icon: React.ReactNode;
    heading: string;
    description: string;
}

const FeatureSummary: React.FC<FeatureSummary> = ({ className, icon, heading, description }) => {
    return (
        <Feature_SummaryStyled className={className}>
            <Feature_Summary_IconStyled>{icon}</Feature_Summary_IconStyled>
            <WidgetHeadingStyled>{heading}</WidgetHeadingStyled>
            <WidgetDescriptionStyled>{description}</WidgetDescriptionStyled>
        </Feature_SummaryStyled>
    );
};

interface ChatMessage {
    className?: string;
    name: string;
    message: string;
}

const ChatMessage: React.FC<ChatMessage> = ({ className, name, message }) => {
    return (
        <ChatMessageStyled className={className}>
            <AvatarStyled>
                <AvatarIcon />
            </AvatarStyled>
            <ChatMessage_DetailsStyled>
                <WidgetHeading_Small_Styled>{name}</WidgetHeading_Small_Styled>
                <WidgetDescriptionStyled>{message}</WidgetDescriptionStyled>
            </ChatMessage_DetailsStyled>
        </ChatMessageStyled>
    );
};

/* SHARED */
const FontFaces = createGlobalStyle`
    @font-face {
        font-family: 'Roobert Heavy Italic';
        src: local('Roobert Heavy Italic'),
            url('/fonts/roobert-heavy-italic.woff2') format('woff2'),
            url('/fonts/roobert-heavy-italic.woff') format('woff'),
            url('/fonts/roobert-heavy-italic.eot') format('eot'),
            url('/fonts/roobert-heavy-italic.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
`;

const ChatMessage_DetailsStyled = styled.div`
    margin-left: ${rem(8)};
    text-align: left;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-left: ${rem(16)};
        text-align: left;
    }
`;

const ChatMessageStyled = styled.div`
    display: flex;
    align-items: flex-start;
    border-radius: ${rem(12)};
    padding: ${rem(20)};
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    box-sizing: border-box;

    & + & {
        margin-top: ${rem(12)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border-radius: ${rem(20)};

        & + & {
            margin-top: ${rem(20)};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        border-radius: ${rem(24)};

        & + & {
            margin-top: ${rem(40)};
        }
    }
`;

const CreatorPartnerBadgeStyled = styled(Centered)`
    height: ${rem(40)};
    padding: 0 ${rem(20)};
    border-radius: ${rem(12)};
    background-color: ${({ theme }) => theme.palette.RedTransparent1};
    ${({ theme }) => theme.typography.Heading16}
    color: ${({ theme }) => theme.palette.Red};
    margin-bottom: ${rem(32)};
    flex-shrink: 0;

    ${CheckStyled} {
        margin-right: ${rem(12)};
    }
`;

/* CREATOR APPLICATION */
const CreatorApplicationStyled = styled(CreatorApplication)`
    overflow-y: auto;
    flex: 1;
`;

const CreatorApplicationOverlayStyled = styled.div<{ visible: boolean }>`
    ${FadeOverlayStyles}
    background-color: ${({ theme }) => theme.palette.WineRed};
`;

/* PRODUCT ROADMAP */
const ProductRoadmapStyled = styled(ProductRoadmap)`
    margin-top: ${rem(40)};
`;

const ProductRoadmapOverlay_ContentsStyled = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: ${rem(28)} ${rem(20)};
    box-sizing: border-box;
    ${({ theme }) => theme.typography.HideScrollbar}

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        padding: ${rem(40)} ${rem(32)};
    }
`;

const ProductRoadmapOverlayStyled = styled.div<{ visible: boolean; slide: boolean }>`
    background-color: ${({ theme }) => theme.palette.WineRed};
    ${FadeOverlayStyles}
    overflow: hidden;

    ${Heading2Styled} {
        max-width: 50%;
        text-align: left;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        right: auto;
        border-top-right-radius: ${rem(32)};
        width: ${rem(640)};
    }
`;

/* FOOTER */
const Footer_HeadingStyled = styled(Heading2Styled)`
    margin-bottom: ${rem(32)};
`;

const Footer_IconContainerStyled = styled(Centered)`
    height: ${rem(100)};
    width: ${rem(100)};
    border-radius: ${rem(24)};
    background-color: ${({ theme }) => theme.palette.RedTransparent1};
    color: ${({ theme }) => theme.palette.Red};
    margin-bottom: ${rem(24)};
    z-index: 0;
    overflow: hidden;

    svg {
        height: ${rem(72)};
        width: ${rem(72)};
    }
`;

const FooterStyled = styled(Footer)`
    margin-top: ${rem(32)};

    * {
        color: ${({ theme }) => transparentize(0.4, theme.palette.Pink)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-top: ${rem(72)};
    }
`;

/* TESTIMONIALS */
const Testimonials_ViewMoreButtonStyled = styled(OutlineButtonStyled)`
    margin-top: ${rem(24)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-top: ${rem(32)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        display: none;
    }
`;

const Testimonials_MessageColumnStyled = styled.div<{ $hidden?: boolean }>`
    display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};
    flex-direction: column;
    align-items: stretch;
    flex: 1;

    & + & {
        margin-top: ${rem(12)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        & + & {
            margin-top: ${rem(20)};
            margin-right: ${rem(20)};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        display: flex;

        & + & {
            margin-top: ${rem(40)};
            margin-right: ${rem(40)};
        }
    }
`;

const Testimonials_MessagesStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: ${rem(40)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-top: ${rem(64)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: ${rem(72)};
    }
`;

const QuoteCard_QuoteStyled = styled(DescriptionStyled)`
    margin-bottom: ${rem(20)};
    line-height: 116%;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(20)};
        margin-bottom: ${rem(24)};
        text-align: left;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        font-size: ${rem(28)};
    }
`;

const QuoteCard_BadgeStyled = styled(Centered)`
    display: flex;
    height: ${rem(32)};
    padding: 0 ${rem(20)};
    border-radius: ${rem(16)};
    background-color: ${({ theme }) => theme.palette.RedTransparent1};
    ${({ theme }) => theme.typography.Heading13}
    color: ${({ theme }) => theme.palette.Red};
`;

const Testimonials_Creator_NameStyled = styled.div`
    display: flex;
    align-items: center;

    ${CheckStyled} {
        margin-left: ${rem(8)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(4)};
    }
`;

const Testimonials_CreatorStyled = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${rem(12)};

    ${WidgetHeadingStyled} {
        margin-bottom: 0;
    }

    ${AvatarStyled} {
        margin-right: ${rem(12)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(20)};
    }
`;

const QuoteCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    ${CreatorPartnerBadgeStyled} {
        margin-bottom: 0;
        margin-top: auto;
    }
`;

const Testimonials_CardStyled = styled.div<{ active: boolean; before: boolean }>`
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    width: calc(100vw - ${rem(40)});
    margin: 0 ${rem(20)};
    padding: ${rem(32)};
    box-sizing: border-box;
    flex-shrink: 0;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    border-radius: ${rem(20)};
    transform: scale(1);
    flex-shrink: 0;
    transition: transform 250ms ease, opacity 250ms ease;
    display: flex;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(32)};
        width: calc(66vw - ${rem(40)});
        max-width: ${rem(800)};
        cursor: pointer;

        ${({ active, before }) =>
            !active &&
            css`
                transform: scale(0.84);
                opacity: 0.5;
                transform-origin: center ${before ? 'right' : 'left'};

                @media (hover: hover) {
                    &:hover {
                        transition: transform 75ms ease, opacity 75ms ease;
                        transform: scale(0.85);
                        opacity: 0.7;
                    }
                }
            `}
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(40)};
        border-radius: ${rem(24)};
    }
`;

const Testimonials_ItemsStyled = styled.div<{ activeIndex: number }>`
    display: flex;
    align-items: stretch;
    width: 100vw;
    overflow: auto;
    padding-bottom: ${rem(24)};
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    ${({ theme }) => theme.typography.HideScrollbar}

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        width: auto;
        left: 0;
        bottom: 0;
        overflow: unset;
        overflow-x: unset;
        transform: ${({ activeIndex }) => `translateX(calc(-1 * 100% * ${activeIndex}))`};
        transition: transform 250ms ease;
    }
`;

const TestimonialsStyled = styled.div`
    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        position: relative;
        width: 66vw;
        max-width: ${rem(840)};
        overflow: visible;
        margin-bottom: ${rem(32)};
    }
`;

const TestimonialsHeadingStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-bottom: ${rem(40)};
    margin-top: ${rem(16)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-top: ${rem(24)};
        margin-bottom: ${rem(64)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        margin-top: ${rem(40)};
        margin-bottom: ${rem(72)};
    }
`;

const CreatorCard_NameStyled = styled.div`
    font-family: 'Roobert Heavy Italic', Helvetica, Arial, sans-serif;
    font-size: ${rem(40)};
    color: ${({ theme }) => theme.palette.White};
    text-transform: uppercase;
    white-space: wrap;
    margin-bottom: ${rem(32)};
    text-align: left;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(48)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        font-size: ${rem(64)};
    }
`;

const CreatorCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    ${Testimonials_CreatorStyled} {
        margin-bottom: 0;
        margin-top: auto;
    }
`;

/* FEATURES */
const Features_HeadingStyled = styled(Heading2Styled)`
    margin-bottom: ${rem(40)};
    padding-top: ${rem(16)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(64)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        padding-top: ${rem(32)};
        margin-bottom: ${rem(80)};
    }
`;

const Feature_Summary_IconStyled = styled(Centered)`
    height: ${rem(80)};
    width: ${rem(80)};
    border-radius: ${rem(24)};
    background-color: ${({ theme }) => theme.palette.RedTransparent1};
    color: ${({ theme }) => theme.palette.Red};
    margin-bottom: ${rem(16)};

    svg {
        height: ${rem(32)};
        width: ${rem(32)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(32)};
    }
`;

const Feature_SummaryStyled = styled.div`
    width: calc(50% - ${rem(16)});
    margin-bottom: ${rem(20)};
    text-align: left;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        width: calc(33% - ${rem(20)});
        margin-bottom: ${rem(40)};
    }
`;

const Feature_SummaryContainerStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    margin-bottom: ${rem(12)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(20)};
    }
`;

const Feature_Card_VideoContainerStyled = styled.div<{ aspectRatio: number }>`
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    z-index: 0;
    overflow: hidden;
    border-radius: ${rem(12)};
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
    flex: 3;
    align-self: center;
    margin-bottom: ${rem(12)};

    video {
        height: 100%;
        width: 100%;
    }
`;

const Feature_Card_DetailsStyled = styled.div`
    flex: 2;
`;

const Feature_CardStyled = styled.div<{ wide?: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    align-self: stretch;
    border-radius: ${rem(20)};
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    padding: ${rem(16)} ${rem(20)};
    flex: 1;
    text-align: left;

    ${DescriptionStyled} {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(32)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        padding: ${rem(40)};
        justify-content: space-between;

        ${({ wide }) =>
            wide
                ? css`
                      flex-direction: row;
                      align-items: flex-end;

                      ${Feature_Card_VideoContainerStyled} {
                          margin-left: ${rem(20)};
                          margin-bottom: 0;
                      }
                  `
                : css`
                      > * {
                          flex: unset;
                      }
                  `}
    }
`;

const Feature_CardRowStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: ${rem(64)};

    > * {
        margin-top: ${rem(20)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        > * {
            margin-top: ${rem(32)};
        }

        margin-bottom: ${rem(80)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        flex-direction: row;
        margin-top: ${rem(40)};

        > * {
            margin-top: 0;
        }

        > *:first-child {
            margin-right: ${rem(20)};
        }

        > *:last-child {
            margin-left: ${rem(20)};
        }
    }
`;

/* HERO */
const WideSectionStyled = styled(SectionStyled)`
    ${SectionStyled} {
        padding-left: 0;
        padding-right: 0;
    }
    margin-top: ${rem(40)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${Heading1Styled} {
            max-width: ${rem(720)};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        margin-top: 0;
        max-width: ${rem(1440)};

        ${Heading1Styled} {
            max-width: ${rem(960)};
        }
    }
`;

const Hero_DescriptionStyled = styled(DescriptionStyled)`
    color: ${({ theme }) => transparentize(0.4, theme.palette.Pink)};
`;

const Hero_PlayButtonStyled = styled(DarkButtonStyled)`
    position: absolute;
    top: ${rem(16)};
    right: ${rem(16)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        top: ${rem(32)};
        right: ${rem(32)};
    }
`;

const Hero_VideoContainerStyled = styled.div`
    width: 100%;
    border-radius: ${rem(12)};
    background-color: ${({ theme }) => theme.palette.DarkGrey1};
    z-index: 0;
    overflow: hidden;
    aspect-ratio: ${16 / 9};
    position: relative;

    video {
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border-radius: ${rem(20)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        border-radius: ${rem(40)};
    }
`;

const Hero_ButtonsStyled = styled(Row)`
    margin-bottom: ${rem(64)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(88)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        margin-bottom: ${rem(104)};
    }

    > * + * {
        margin-left: ${rem(20)};
    }
`;

/* Container */
const MarketingStyled = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    ${({ theme }) => css`
        background: linear-gradient(
            to bottom,
            ${theme.palette.WineRed} 0%,
            ${theme.palette.WineRed} 10%,
            ${theme.palette.Black} 25%,
            ${theme.palette.Black} 80%,
            ${theme.palette.WineRed} 100%
        );
    `}
`;

export default Marketing;
